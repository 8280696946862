import React from "react"

// markup
const NotFoundPage: React.FC = () => {
  return (
    <p>not found!</p>
  )
}

export default NotFoundPage
